import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import './AboutComponent.css'

function AboutComponent(){
    return (
        <Container maxWidth="sm">
            <Box sx={{ height: '100vh'}}>
                <p className={'about-container'}>
                    We are a team of software engineers dedicated to finding the very best solutions for our clients.
                </p>
            </Box>
        </Container>
    );
}

export default AboutComponent