import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import EmailIcon from '@mui/icons-material/Email';
import './ContactComponent.css'

function ContactComponent(){
    return(
        <Container maxWidth="sm">
            <Box sx={{ height: '100vh'}}>
                <p className={'contact-container'}>
                    <h1>Questions?</h1>
                    <a href={'mailto:spiderbyte.sites@gmail.com'}>
                        <EmailIcon className={'email-icon'} /> 
                        spiderbyte.sites@gmail.com
                    </a>
                </p>
            </Box>
        </Container>
    );
}

export default ContactComponent;