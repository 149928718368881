import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

function AccordionComponent(props){
    return (
      <div className={'accordion-container'}>
        {
          props.accordionList.map(accordion => 
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={ "panel" + accordion.id + "a-content" }
                id={ "panel" + accordion.id + "a-header" }
              >
                <Typography>{accordion.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {accordion.description}
                </Typography>
              </AccordionDetails>
            </Accordion>
          )
        }
      </div>
    );
}

export default AccordionComponent;