import { Outlet } from "react-router-dom";
import './index.css'
import HeaderComponent from "./Components/HeaderComponent/HeaderComponent";
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

function Layout() {
    return(
        <>
            <HeaderComponent />
            {
                <React.Fragment>
                    <CssBaseline />
                    <Container maxWidth="lg">
                        <Box sx={{ bgcolor: '#F2F2F2', height: '100vh' }}>
                            <Outlet />
                        </Box>
                    </Container>
                </React.Fragment>
            }
        </>
    );
}

export default Layout;
