import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import './NotFoundComponent.css'

function NotFoundComponent(){
    return(
        <Container maxWidth="sm">
            <Box sx={{ height: '100vh'}}>
                <p className={'not-found-container'}>
                    Page Not Found.
                </p>
            </Box>
        </Container>
    );
}

export default NotFoundComponent;