import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import './index.css';
import Layout from './layout';
import HomeComponent from './Components/HomeComponent/HomeComponent';
import AboutComponent from './Components/AboutComponent/AboutComponent';
import ContactComponent from './Components/ContactComponent/ContactComponent';
import NotFoundComponent from './Components/NotFoundComponent/NotFoundComponent';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Routes>
          <Route path="/" element={ <Layout/> }>
            <Route index element={ <HomeComponent/> } />
            <Route path={'about'} element={ <AboutComponent/> } />
            <Route path={'contact'} element={ <ContactComponent/> } />
            <Route path={'*'} element={ <NotFoundComponent />} />
          </Route>
      </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
